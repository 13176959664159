import { z } from 'zod'

import { selectVendorSchema } from '@repo/db/schema'

export const UpdateVendorTagSchema = z.object({
  intent: z.literal('updateVendorTags'),
  vendorId: selectVendorSchema.shape.id,
  tagIds: z
    .string()
    .transform((v) => v.split(',').map(Number).filter(Boolean))
    .catch([])
})
