import { Link } from '@remix-run/react'
import type { Vendor } from '@repo/db/schema'
import type { Tag as TagType } from '@repo/db/schema'
import { Badge } from '@repo/ui/components/Badge.js'
import { Button } from '@repo/ui/components/Button.js'
import { Checkbox } from '@repo/ui/components/Checkbox.js'
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@repo/ui/components/Command.js'
import { Popover, PopoverContent, PopoverTrigger } from '@repo/ui/components/Popover.js'
import { Tag } from '@repo/ui/components/Tag.js'
import { cn } from '@repo/ui/utils/utils'
import { useState } from 'react'
import { useFetcherForm } from '~/hooks/useFetcherForm'
import { useOrgTags } from '../_app/useOrgTags'
import { UpdateVendorTagSchema } from './routeConfig'

type OrganizationTag = ReturnType<typeof useOrgTags>[number]

type TagEditorProps = {
  vendorId: Vendor['id']
  tagIds: TagType['id'][]
}

const FOOTER_HEIGHT = 40

export const TagEditor = ({ vendorId, tagIds }: TagEditorProps) => {
  const formId = `${UpdateVendorTagSchema.shape.intent.value}_${vendorId}`

  const allTags = useOrgTags()

  const [popoverOpen, setPopoverOpen] = useState(false)

  const { submit, optimisticValues } = useFetcherForm({
    schema: UpdateVendorTagSchema,
    intent: UpdateVendorTagSchema.shape.intent.value,
    key: formId,
    config: { action: '/api/vendor-tags' }
  })

  const optimisticTagIds = optimisticValues?.tagIds ?? tagIds

  const selectedTags = allTags.filter((t) => optimisticTagIds.includes(t.id))

  const handleToggleTag = (tagId: OrganizationTag['id']) => {
    const isSelected = optimisticTagIds.includes(tagId)
    return isSelected ? optimisticTagIds.filter((t) => t !== tagId) : [...optimisticTagIds, tagId]
  }

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className="min-h-7 w-full justify-start h-auto min-w-48 p-0 border-none"
        >
          <TagList tags={selectedTags} showAll={popoverOpen} />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="w-[var(--radix-popover-trigger-width)] max-w-72 p-1 relative"
      >
        <FilteredTagList
          allTags={allTags}
          selectedTags={selectedTags}
          onSelect={(tagId) => {
            const newTagIds = handleToggleTag(tagId)
            submit({ tagIds: newTagIds.join(','), vendorId })
          }}
        />

        <div
          className="absolute w-full flex items-center bottom-0 left-0 border-t rounded-b-md p-2"
          style={{
            height: FOOTER_HEIGHT
          }}
        >
          <Link
            to="/settings"
            className="underline text-xs text-muted-foreground underline-offset-2"
          >
            Manage tags
          </Link>
        </div>
      </PopoverContent>
    </Popover>
  )
}

const TagList = ({
  tags,
  showAll
}: {
  tags: OrganizationTag[]
  showAll: boolean
}) => {
  const visibleTagCount = showAll ? Number.MAX_SAFE_INTEGER : 3
  const hiddenTagCount = tags.length - visibleTagCount

  return (
    <div className="flex items-center gap-1 flex-wrap">
      {tags
        .sort((a, b) => a.name.localeCompare(b.name))
        .slice(0, visibleTagCount)
        .map((tag) => {
          return (
            <Tag
              key={tag.id}
              backgroundColor={tag.backgroundColor}
              textColor={tag.textColor}
              size="sm"
              className="flex items-center gap-1"
            >
              {tag.name}
            </Tag>
          )
        })}
      <Badge
        variant="secondary"
        size="sm"
        className={cn(!showAll && hiddenTagCount > 0 ? 'flex' : 'hidden')}
      >
        +{hiddenTagCount}
      </Badge>
    </div>
  )
}

const FilteredTagList = ({
  allTags,
  selectedTags,
  onSelect
}: {
  allTags: OrganizationTag[]
  selectedTags: OrganizationTag[]
  onSelect: (tagId: OrganizationTag['id']) => void
}) => {
  const [filteredTags, setFilteredTags] = useState<OrganizationTag[]>(allTags)

  const handleSearch = (q: string) => {
    setFilteredTags(allTags?.filter((t) => t.name.toLowerCase().includes(q.toLowerCase())))
  }

  return (
    <Command shouldFilter={false} style={{ paddingBottom: FOOTER_HEIGHT }}>
      <CommandInput placeholder="Search tags..." onValueChange={handleSearch} autoFocus />

      <CommandList>
        <CommandGroup>
          {filteredTags.map((tag) => {
            const isSelected = selectedTags.some((t) => t.id === tag.id)
            return (
              <CommandItem
                key={tag.id}
                value={String(tag.id)}
                onSelect={(tagId) => {
                  const tag = allTags.find((t) => t.id === +tagId)
                  if (!tag) return
                  onSelect(tag.id)
                }}
                className="flex items-center gap-1"
              >
                <Checkbox checked={isSelected} />

                <Tag
                  backgroundColor={tag.backgroundColor}
                  textColor={tag.textColor}
                  size="sm"
                  className="flex items-center gap-1"
                >
                  {tag.name}
                </Tag>
              </CommandItem>
            )
          })}
        </CommandGroup>
      </CommandList>
    </Command>
  )
}
